import { lazy, Suspense, Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

// Import UIKit
import UIkit from "uikit";
import "uikit/dist/css/uikit.min.css";
import Icons from "uikit/dist/js/uikit-icons";

// Import Analytics and Feature flags
import { configCatClient } from "./analytics/configcat";

// Style sheet override
import "./App.scss";

import ScrollToTop from "./components/scrollTop";
import NavBar from "./components/navbar";
import Footer from "./components/footer";

const Home = lazy(() => import("./pages/home"));
const Work = lazy(() => import("./pages/work"));
const Stats = lazy(() => import("./pages/stats"));
const Contact = lazy(() => import("./pages/contact"));
const renderLoader = () => (
  <div
    className="uk-flex uk-flex-middle uk-position-absolute uk-position-center spinner-container"
    data-uk-height-viewport
  >
    <div className="ah--spinner" uk-spinner="ratio: 2"></div>
  </div>
);

UIkit.use(Icons);

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      dark: false,
      isUnderConstruction: false,
    };

    this.toggleTheme = this.toggleTheme.bind(this);
  }

  componentDidMount() {
    this.readTheme();
    console.log(this.readLanguage());

    // Load configCat values
    configCatClient
      .getValueAsync("isUnderConstruction", false)
      .then((value) => {
        this.setState({
          isUnderConstruction: value,
        });
      });
  }

  readLanguage(): string {
    const userLanguage: string = window.navigator.language;
    return userLanguage;
  }

  setTheme(mode: boolean): void {
    let obj = {
      dark: mode,
    };

    localStorage.setItem("ah_settings", JSON.stringify(obj));
  }

  toggleTheme(): void {
    this.setState(
      {
        dark: !this.state.dark,
      },
      () => {
        this.setTheme(this.state.dark);
      }
    );
  }

  readTheme(): void {
    let settingRaw = localStorage.getItem("ah_settings");

    interface MyObj {
      dark: boolean;
    }

    if (settingRaw !== null) {
      let settingJson: MyObj = JSON.parse(settingRaw);
      this.setState({
        dark: settingJson.dark || false,
      });
    } else {
      this.setState({
        dark:
          (window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches) ||
          false,
      });
    }
  }

  render() {
    const isUnderConstruction = this.state.isUnderConstruction;

    if (isUnderConstruction) {
      return (
        <div
          className={`uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-text-center ${
            this.state.dark
              ? "uk-light uk-background-secondary"
              : "uk-dark uk-background-muted"
          }`}
          data-uk-height-viewport
        >
          <h1 className="uk-text-uppercase ">
            Something big is coming&hellip;
          </h1>
          <p>Come back again, I have something cool up my sleeves</p>
        </div>
      );
    } else {
      return (
        <Suspense fallback={renderLoader()}>
          <Router>
            <ScrollToTop />
            <div
              className={`App ${
                this.state.dark
                  ? "uk-light uk-background-secondary"
                  : "uk-dark uk-background-muted"
              }`}
            >
              <NavBar dark={this.state.dark} toggle={this.toggleTheme} />
              <main tabIndex={-1}>
                <Switch>
                  <Route exact path="/">
                    <Home dark={this.state.dark} />
                  </Route>
                  <Route path="/work">
                    <Work dark={this.state.dark} />
                  </Route>
                  <Route path="/stats">
                    <Stats dark={this.state.dark} />
                  </Route>
                  <Route path="/contact">
                    <Contact
                      dark={this.state.dark}
                    />
                  </Route>
                  <Route path="*">
                    <Home dark={this.state.dark} />
                  </Route>
                </Switch>
              </main>
              <Footer />
            </div>
          </Router>
        </Suspense>
      );
    }
  }
}

export default Sentry.withProfiler(App, { name: "MainWebApp" });
