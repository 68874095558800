import { Link, useRouteMatch } from "react-router-dom";
import UIkit from "uikit";
import "./navbar.scss";

import Toggle from "./toggle";

interface NavProps {
  dark: boolean;
  toggle: any;
}

export default function NavBar(props: NavProps) {
  const navElements = [
    {
      label: "About",
      url: "/",
      activeOnlyWhenExact: true,
    },
    {
      label: "Work",
      url: "/work",
      activeOnlyWhenExact: false,
    },
    {
      label: "Stats",
      url: "/stats",
      activeOnlyWhenExact: false,
    },
    {
      label: "Contact",
      url: "/contact",
      activeOnlyWhenExact: false,
    },
  ];


  const scrollMain = () => {
    UIkit.scroll("#main").scrollTo("#main");
  }

  return (
    <header className="uk-position-fixed uk-position-z-index uk-width-1-1">
      <Link
        className="skip-link uk-position-absolute"
        to={{
          hash: "#main",
        }}
        onClick={scrollMain}
        aria-label="Skip to main content"
        data-uk-scroll
      >
        Skip to main content
      </Link>

      {/* Desktop navigation panel */}
      <div className="uk-container uk-container-large uk-margin-auto">
        <nav
          className="uk-padding uk-navbar-container uk-navbar-transparent uk-flex uk-flex-between ah--navbar uk-visible@s"
          aria-label="Main navigation"
          data-uk-navbar
        >
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
              {navElements.map((data, index) => {
                return (
                  <MenuLink
                    key={index}
                    to={data.url}
                    label={data.label}
                    activeOnlyWhenExact={data.activeOnlyWhenExact}
                  />
                );
              })}
            </ul>
            <div className="uk-margin-left">
              <Toggle dark={props.dark} inMenu={false} toggle={props.toggle} id="desktopToggle" />
            </div>
          </div>
        </nav>
      </div>
      {/* Mobile navigation panel */}
      <nav
        className="uk-navbar-container uk-navbar-transparent uk-hidden@s"
        aria-label="Main Menu"
        data-uk-navbar
      >
        <div className="uk-navbar-right">
          <ul className="uk-navbar-nav">
            <li>
              <Link
                to={{
                  hash: "#",
                }}
                aria-label="Open the menu"
                data-uk-toggle="target: #offcanvas"
              >
                <span
                  className="uk-padding-small"
                  data-uk-icon="icon: menu; ratio: 1"
                  aria-hidden="true"
                ></span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <div id="offcanvas" data-uk-offcanvas="mode: none; flip: true">
        <div className="uk-offcanvas-bar uk-flex uk-flex-column">
          <button
            className="uk-padding-small uk-offcanvas-close"
            type="button"
            data-uk-close
          >
            <span className="visuallyHidden">Close</span>
          </button>
          <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
            {navElements.map((data, index) => {
              return (
                <li key={index}>
                  <Link to={data.url} className="uk-text-uppercase">
                    {data.label}
                  </Link>
                </li>
              );
            })}

            <div className="uk-position-bottom uk-padding">
              <Toggle dark={props.dark} inMenu={true} toggle={props.toggle} id="mobileToggle" />
            </div>
          </ul>
        </div>
      </div>
    </header>
  );
}

function MenuLink({ label = "", to = "", activeOnlyWhenExact = false }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <li className={match ? "uk-active" : ""}>
      <Link to={to}>{label}</Link>
    </li>
  );
}
