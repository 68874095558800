import { useState, useEffect } from "react";
import { configCatClient } from "../analytics/configcat";
import moment from "moment/moment";
import "./footer.scss";

function returnCurrentYear() {
  let now = new Date();
  let isoData = now.toISOString();
  let year = moment(isoData).format("YYYY");
  return year;
}

const Footer = () => {
  const [lastUpdated, setlastUpdated] = useState("");

  useEffect(() => {
    // Load configCat values
    configCatClient.getValueAsync("lastDeployed", false).then((value) => {
      setlastUpdated(moment(value).fromNow());
    });
  });

  const footerSocialElements = [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/alanho",
      tooltip: "Connect over LinkedIn",
      icon: "linkedin",
    },
    {
      name: "Twitter",
      url: "https://www.twitter.com/alanhoUX",
      tooltip: "Follow on Twitter",
      icon: "twitter",
    },
    {
      name: "Behance",
      url: "https://www.behance.net/alanho",
      tooltip: "Follow on Behance",
      icon: "behance",
    },
  ];

  return (
    <footer className="uk-container-expand uk-margin-xlarge-top uk-flex uk-flex-center" role="contentinfo">
      <div
        className="uk-container-large uk-padding uk-child-1-1 uk-child-width-expand@s"
        data-uk-grid
      >
        <div>
          <p className="uk-text-center uk-text-left@s">
            <small>Last updated {lastUpdated}</small>
            <br />
            <small>
              &copy; Alan Ho,&nbsp;<time>{returnCurrentYear()}</time>
            </small>
          </p>
        </div>
        <nav aria-label="Social Navigation">
          <ul className="uk-list uk-flex uk-flex-middle uk-flex-center uk-flex-right@s uk-margin-remove-vertical">
            {footerSocialElements.map((data, index) => {
              return (
                <li
                  key={index}
                  className="uk-margin-remove-vertical uk-text-center uk-padding-small"
                >
                  <a
                    aria-describedby="a11y-message--new-window"
                    href={data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={data.tooltip}
                    data-uk-tooltip="offset:0"
                    aria-label={data.name}
                  >
                    <span uk-icon={`icon: ${data.icon}; ratio: 0.8`} />
                  </a>
                </li>
              );
            })}
          </ul>
          <span aria-hidden="true" className="visuallyHidden" id="a11y-message--new-window">(opens new window)</span>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
