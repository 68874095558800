import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: "https://b57c48a3289b4d1b9f1dee1289cb2aa5@o84958.ingest.sentry.io/5776176",
  autoSessionTracking: true,
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// Register service workers to make app to work offline and load faster
// https://cra.link/PWA
serviceWorkerRegistration.unregister();
