import "./toggle.scss";

export default function Toggle(props: {
  dark: boolean;
  inMenu: boolean;
  toggle: any;
  id: string;
}) {
  return (
    <div id="toggler" className="uk-flex uk-flex-center">
      <div
        className="ThemeToggle"
        data-uk-tooltip={`title: Switch to ${
          props.dark ? "light" : "dark"
        } theme;${props.inMenu ? "pos: top" : "pos: bottom"}`}
      >
        <input
          type="checkbox"
          id={props.id}
          checked={props.dark}
          onChange={props.toggle}
          className="ThemeToggle--input uk-position-absolute uk-hidden"
        />
        <label className="ThemeToggle--label" htmlFor={props.id}>
          <div className="ThemeToggle--toggle-handle uk-position-absolute"></div>
          <div
            className={`ThemeToggle--toggle-handle-icon uk-position-absolute ThemeToggle--sun ${
              props.inMenu && "inMenu"
            }`}
          >
            <svg
              viewBox="0 0 16 16"
              fill="currentColor"
              role="img"
              aria-labelledby="sun-title"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <title id="sun-title">Switch to light theme</title>
              <path d="M7.333 0v2h1.334V0H7.333ZM2.815 1.872l-.943.943 1.414 1.414.943-.943-1.414-1.414Zm10.37 0-1.414 1.414.943.943 1.414-1.414-.943-.943ZM8 3.333A4.678 4.678 0 0 0 3.333 8 4.678 4.678 0 0 0 8 12.667 4.678 4.678 0 0 0 12.667 8 4.678 4.678 0 0 0 8 3.333Zm0 1.334A3.323 3.323 0 0 1 11.333 8 3.323 3.323 0 0 1 8 11.333 3.323 3.323 0 0 1 4.667 8 3.323 3.323 0 0 1 8 4.667ZM0 7.333v1.334h2V7.333H0Zm14 0v1.334h2V7.333h-2ZM3.286 11.771l-1.414 1.414.943.943 1.414-1.414-.943-.943Zm9.428 0-.943.943 1.414 1.414.943-.943-1.414-1.414ZM7.333 14v2h1.334v-2H7.333Z"></path>
            </svg>
          </div>
          <div
            className={`ThemeToggle--toggle-handle-icon uk-position-absolute ThemeToggle--moon ${
              props.inMenu && "inMenu"
            }`}
          >
            <svg
              viewBox="0 0 16 16"
              fill="currentColor"
              role="img"
              aria-labelledby="moon-title"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <title id="moon-title">Switch to dark theme</title>
              <path d="M7.067 3.087a5 5 0 005.466 7.026 5 5 0 11-5.466-7.026z"></path>
            </svg>
          </div>
        </label>
      </div>
    </div>
  );
}
